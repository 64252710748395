import { Theme, darken } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useHotTrendsStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      display: "flex",
      backgroundColor: "white",
    },
    containerInner: {
      width: "1175px",
      margin: "0 auto",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      padding: "150px 0px 80px 0px",
      [theme.breakpoints.down(1100)]: {
        padding: "56px 0 56px 0",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        overflowX: "hidden",
      },
      [theme.breakpoints.between("sm", 1250)]: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    left: {
      maxWidth: "391px",
      [theme.breakpoints.down(1100)]: {
        padding: "0 16px",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "unset",
      },
    },
    right: {
      display: "flex",
    },
    title: {
      fontSize: "2.5rem",
      fontWeight: "800",
      lineHeight: "1.17",
      color: "#f98012",

      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
        lineHeight: "1.2",
      },
    },
    subtitle: {
      fontSize: "1.0625rem",
      lineHeight: "1.41",
      color: "#3f3d56",
      paddingTop: "12px",
      [theme.breakpoints.down("sm")]: {
        lineHeight: "unset",
      },
    },
    buttonsDesktop: {
      display: "flex",
      paddingTop: theme.spacing(3),
      gap: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    buttonsMobile: {
      display: "none",
      paddingTop: "19px",
      gap: theme.spacing(3),
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "0px",
        width: "100%",
      },
    },
    btnLogin: {
      width: "168px",
      height: "48px",
      fontWeight: "800",
      display: "inline-flex",
      fontSize: "1.0625rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      justifyContent: "center",
      minHeight: "unset",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "56px",
      },
    },
    btnSignup: {
      width: "168px",
      height: "48px",
      fontWeight: "800",
      display: "inline-flex",
      fontSize: "1.0625rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      justifyContent: "center",
      minHeight: "unset",
      backgroundColor: "white",
      border: "3px solid #f98012",
      color: "#f98012",
      "&:hover": {
        backgroundColor: "white !important",
        color: darken("#f98012", 0.05),
        borderColor: darken("#f98012", 0.05),
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "56px",
      },
    },
  };
});
