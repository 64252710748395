import { Theme, darken } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useHeroStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      display: "flex",
      backgroundColor: "white",
      minHeight: "831px",
      marginTop: "104px",
      [theme.breakpoints.down(1100)]: {
        minHeight: "unset",
        marginTop: "77px",
      },
    },
    innerWithAnouncement: {
      marginTop: "54px",
      [theme.breakpoints.down(1100)]: {
        flexDirection: "column",
        marginTop: "96px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "64px",
      },
    },
    containerInner: {
      maxWidth: "1175px",
      width: "100%",
      margin: "0 auto",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(1100)]: {
        flexDirection: "column",
        marginTop: "36px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "24px",
      },
      [theme.breakpoints.between("sm", 1250)]: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    left: {
      maxWidth: "455px",
      [theme.breakpoints.down(1100)]: {
        maxWidth: "unset",
      },
    },
    right: {
      display: "flex",
      alignItems: "center",
      marginRight: "-85px",
      minWidth: "750px",
      minHeight: "750px",
      [theme.breakpoints.down(1100)]: {
        minWidth: "unset",
        minHeight: "unset",
        "& video": {
          width: "100%",
        },
        marginRight: "0px",
      },
      [theme.breakpoints.between("sm", 1250)]: {
        minWidth: "unset",
        marginRight: "0",
      },

      "& video": {
        minWidth: "750px",
        minHeight: "750px",
        [theme.breakpoints.between("sm", 1250)]: {
          minWidth: "unset",
        },
        [theme.breakpoints.down(1100)]: {
          minWidth: "100%",
          minHeight: "100%",
        },
      },
    },
    heroTitle: {
      fontSize: "2.5rem",
      fontWeight: "800",
      lineHeight: "1.2",
      color: "#f98012",
      [theme.breakpoints.down(1100)]: {
        paddingLeft: "16px",
        paddingRight: "16px",
        fontSize: "2rem",
        lineHeight: "1.5",
      },
    },
    heroSubtitle: {
      fontSize: "1.0625rem",
      lineHeight: "1.41",
      color: "#3f3d56",
      paddingTop: "16px",
      [theme.breakpoints.down(1100)]: {
        gap: "unset",
        paddingTop: "15px",
        paddingLeft: "16px",
        paddingRight: "16px",
        lineHeight: "unset",
      },
    },
    heroSubtitleDesc: {
      paddingTop: "16px",
      "& p": {
        fontSize: "0.9375rem",
        paddingBottom: "16px",
        "&:last-child": {
          paddingBottom: "0px",
        },
      },
    },
    heroAppTeaser: {
      color: "#3f3d56",
      fontSize: "1.3125rem",
      fontWeight: "800",
      lineHeight: "1.14",
      paddingTop: "16px",
      [theme.breakpoints.down(1100)]: {
        paddingLeft: "16px",
        paddingRight: "16px",
        lineHeight: "1.41",
        textAlign: "center",
        fontSize: "1.0625rem",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "16px",
        paddingRight: "16px",
        lineHeight: "1.41",
        fontSize: "1.0625rem",
        textAlign: "left",
      },
    },
    appstore: {
      display: "flex",
      gap: theme.spacing(3),
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down(1100)]: {
        justifyContent: "center",
        padding: "0 16px",
        paddingTop: "24px",
        gap: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
      },
    },
    buttonsMobile: {
      display: "none",
      paddingTop: "19px",
      gap: theme.spacing(3),
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.down(1100)]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "0px",
        width: "100%",
        paddingBottom: "56px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "unset",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "0px",
        width: "100%",
        paddingBottom: "56px",
      },
    },
    btnLogin: {
      width: "168px",
      height: "48px",
      fontWeight: "800",
      display: "inline-flex",
      fontSize: "1.0625rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      justifyContent: "center",
      minHeight: "unset",
      [theme.breakpoints.down(1100)]: {
        width: "100%",
        height: "56px",
      },
    },
    btnSignup: {
      width: "168px",
      height: "48px",
      fontWeight: "800",
      display: "inline-flex",
      fontSize: "1.0625rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      justifyContent: "center",
      minHeight: "unset",
      backgroundColor: "white",
      border: "3px solid #f98012",
      color: "#f98012",
      "&:hover": {
        backgroundColor: "white !important",
        color: darken("#f98012", 0.05),
        borderColor: darken("#f98012", 0.05),
      },
      [theme.breakpoints.down(1100)]: {
        width: "100%",
        height: "56px",
      },
    },
    link: {
      cursor: "pointer",
    },
    noPadding: {
      padding: "0px",
    },
  };
});
