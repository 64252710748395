import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useBlogStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      display: "flex",
      zIndex: "1",
      backgroundColor: "#fff",
    },
    containerInner: {
      width: "1175px",
      margin: "0 auto",
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing("50px", 0),
      [theme.breakpoints.down(1100)]: {
        padding: theme.spacing("10px", "16px", "4px", "16px"),
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing("10px", "16px", "4px", "16px"),
        alignItems: "flex-start",
      },
    },
    sectionTitle: {
      fontSize: "2.5rem",
      fontWeight: "800",
      lineHeight: "1.33",
      color: "#3f3d56",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        fontSize: "2rem",
        lineHeight: "1.25",
        width: 288,
      },
    },
    sectionSubTitle: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      color: "#3f3d56",
      fontSize: "1.0625rem",
      lineHeight: "1.41",
      textAlign: "center",
      maxWidth: "828px",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        lineHeight: "unset",
      },
    },
    row1: {
      display: "flex",
      gap: "78px",
    },
    link: {
      fontSize: "1.3125rem",
      color: "#f98012",
      lineHeight: "1.14",
      fontWeight: 800,
    },
    btnLogin: {
      width: "168px",
      height: "48px",
      fontWeight: "800",
      display: "inline-flex",
      fontSize: "1.0625rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      justifyContent: "center",
      minHeight: "unset",
    },
  };
});
