import React from "react";

import { Theme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import useMediaQuery from "@mui/material/useMediaQuery";

import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import Link from "@/common/components/Link";

import CalendarIcon from "@/common/icons/CalendarIcon";
import ConversationIcon from "@/common/icons/ConversationIcon";
import GroupsIcon from "@/common/icons/GroupsIcon";
import AccordeonArrow from "@/common/icons/landing-page/AccordeonArrow";

import { useToolkitStyles } from "./Toolkit.styles";
export const Toolkit = () => {
  const { classes } = useToolkitStyles();
  const accordeonBreakpoint = useMediaQuery((theme: Theme) => theme.breakpoints.down(756));
  const { t } = useTranslation();

  const row1 = [
    {
      id: 0,
      title: t("Main.Groups"),
      description: t("LandingPage.Toolkit.Groups.Text"),
      icon: <GroupsIcon width="32" height="32" color="#fff" />,
      linkText: t("LandingPage.Toolkit.Groups.LinkText"),
      linkHref: "/groups",
      defaultExpanded: true,
    },
    {
      id: 1,
      title: t("Main.Debates"),
      description: t("LandingPage.Toolkit.Debates.Text"),
      icon: <ConversationIcon width="32" height="32" fill="#fff" />,
      linkText: t("LandingPage.Toolkit.Debates.LinkText"),
      linkHref: "/debates",
      defaultExpanded: false,
    },
    {
      id: 2,
      title: t("Main.Events"),
      description: t("LandingPage.Toolkit.Events.Text"),
      icon: <CalendarIcon width="32" height="32" fill="#fff" />,
      linkText: t("LandingPage.Toolkit.Events.LinkText"),
      linkHref: "/events",
      defaultExpanded: false,
    },
  ];
  const row2 = [
    {
      id: 0,
      title: t("Main.Blogs"),
      description: t("LandingPage.Toolkit.Blogs.Text"),
      icon: <GroupsIcon width="32" height="32" color="#fff" />,
      linkText: t("LandingPage.Toolkit.Blogs.LinkText"),
      linkHref: "/blogs",
      defaultExpanded: false,
    },
    {
      id: 1,
      title: t("Main.Petitions"),
      description: t("LandingPage.Toolkit.Petitions.Text"),
      icon: <ConversationIcon width="32" height="32" fill="#fff" />,
      linkText: t("LandingPage.Toolkit.Petitions.LinkText"),
      linkHref: "/petitions",
      defaultExpanded: false,
    },
    {
      id: 2,
      title: t("Main.Livestreams"),
      description: t("LandingPage.Toolkit.Livestreams.Text"),
      icon: <CalendarIcon width="32" height="32" fill="#fff" />,
      linkText: t("LandingPage.Toolkit.Livestreams.LinkText"),
      linkHref: "/login?referralPath=livestreams",
      defaultExpanded: false,
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, y: ["-20px", "0px"] }}
          transition={{ duration: 0.5 }}
          className={classes.sectionTitle}>
          {t("LandingPage.Toolkit.SectionTitle")}
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, y: ["-20px", "0px"] }}
          transition={{ duration: 0.5 }}
          className={classes.sectionSubTitle}>
          {t("LandingPage.Toolkit.SectionSubtitle")}
        </motion.div>

        {!accordeonBreakpoint ? (
          <div>
            <motion.div
              initial={{ opacity: 1, scale: 0.95 }}
              whileInView={{ opacity: 1, scale: 1, y: ["-10px", "0px"] }}
              transition={{ duration: 0.4 }}
              className={classes.row1}>
              {row1.map((cell) => (
                <div key={`row1_${cell.id}`} className={classes.rowCell}>
                  <div className={classes.header}>
                    <div className={classes.headerIconWrapper}>
                      <div className={classes.headerIconRound}>{cell.icon}</div>
                    </div>
                    <div className={classes.headerTitle}>{cell.title}</div>
                  </div>
                  <div className={classes.description}>{cell.description}</div>
                  <div className={classes.link}>
                    <Link href={cell.linkHref}>
                      <a>{cell.linkText}</a>
                    </Link>
                  </div>
                </div>
              ))}
            </motion.div>
            <motion.div
              initial={{ opacity: 1, scale: 0.95 }}
              whileInView={{ opacity: 1, scale: 1, y: ["10px", "0px"] }}
              transition={{ duration: 0.4 }}
              className={classes.row2}>
              {row2.map((cell) => (
                <div key={`row1_${cell.id}`} className={classes.rowCell}>
                  <div className={classes.header}>
                    <div className={classes.headerIconWrapper}>
                      <div className={classes.headerIconRound}>{cell.icon}</div>
                    </div>
                    <div className={classes.headerTitle}>{cell.title}</div>
                  </div>
                  <div className={classes.description}>{cell.description}</div>
                  <div className={classes.link}>
                    <Link href={cell.linkHref}>
                      <a>{cell.linkText}</a>
                    </Link>
                  </div>
                </div>
              ))}
            </motion.div>
          </div>
        ) : (
          <div>
            <div className={classes.row1}>
              {row1.map((cell) => (
                <Accordion
                  elevation={0}
                  classes={{ root: classes.accordionRoot }}
                  key={`row1_${cell.id}`}
                  data-cy="SettingsAccount__email"
                  defaultExpanded={cell.defaultExpanded}>
                  <AccordionSummary
                    classes={{ root: classes.accordionSummaryRoot }}
                    expandIcon={<AccordeonArrow />}>
                    <div className={classes.header}>
                      <div className={classes.headerIconWrapper}>
                        <div className={classes.headerIconRound}>{cell.icon}</div>
                      </div>
                      <div className={classes.headerTitle}>{cell.title}</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                    <div className={classes.description}>{cell.description}</div>
                    <div className={classes.link}>
                      <Link href={cell.linkHref}>
                        <a>{cell.linkText}</a>
                      </Link>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            <div className={classes.row2}>
              {row2.map((cell) => (
                <Accordion
                  elevation={0}
                  classes={{ root: classes.accordionRoot }}
                  key={`row2_${cell.id}`}
                  data-cy="SettingsAccount__email">
                  <AccordionSummary
                    classes={{ root: classes.accordionSummaryRoot }}
                    expandIcon={<AccordeonArrow />}>
                    <div className={classes.header}>
                      <div className={classes.headerIconWrapper}>
                        <div className={classes.headerIconRound}>{cell.icon}</div>
                      </div>
                      <div className={classes.headerTitle}>{cell.title}</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                    <div className={classes.description}>{cell.description}</div>
                    <div className={classes.link}>
                      <Link href={cell.linkHref}>
                        <a>{cell.linkText}</a>
                      </Link>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
