import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "@/common/components/button/Button";
import ErrorMessage from "@/common/components/ErrorMessage";
import Spinner from "@/common/components/Spinner";

import AlertIcon from "@/common/icons/AlertIcon";

import { useNewslettersStore } from "@/newsletters/hooks";

import { EMAIL_PATTERN } from "@/common/constants/validation";

import { useNewsletterStyles } from "./Newsletter.styles";
export const Newsletter = () => {
  const { classes, cx } = useNewsletterStyles();
  const { t, i18n } = useTranslation();
  const router = useRouter();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const placeholder = t("LandingPage.SubscribeNewsletter.Placeholder", { lng: router.locale });
  const {
    subscribeNewsletter,
    loading: isLoading,
    successful,
    errorMessages,
  } = useNewslettersStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: resetForm,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmitHandler = async (data: any) => {
    const response = await subscribeNewsletter({
      email: data.email,
      language: i18n.language,
    });

    if (response.data?.subscribeNewsletter?.successful) {
      resetForm({ email: "" });
    }
  };

  function getEmailErrorMessage() {
    if (errors.email) {
      return t(`LandingPage.SubscribeNewsletter.EmailAddress.Validation.${errors.email.type}`);
    }

    return (
      t(`LandingPage.SubscribeNewsletter.EmailAddress.Validation.${errorMessages[0]?.message}`) ||
      ""
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.bgSquare}></div>
      <div className={classes.containerInner}>
        <div className={classes.fgSquare}>
          <div className={classes.sectionTitle}>
            {successful
              ? t("LandingPage.SubscribeNewsletter.TitleSent")
              : t("LandingPage.SubscribeNewsletter.SectionTitle")}
          </div>
          <div className={classes.sectionSubTitle}>
            {successful
              ? t("LandingPage.SubscribeNewsletter.DescriptionSent")
              : t("LandingPage.SubscribeNewsletter.SectionSubtitle")}
          </div>
          <div className={classes.formContainer}>
            <form
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
              className={cx(classes.form)}
              id="newsletter">
              <input
                {...register("email", {
                  required: true,
                  pattern: EMAIL_PATTERN,
                })}
                type={"email"}
                aria-label={placeholder}
                placeholder={placeholder}
              />
              {isMobile ? (
                <div className={classes.submitButton}>
                  <Button className={classes.btnSignupMobile} type="submit">
                    {isLoading ? (
                      <Spinner color="#fff" variant="small" />
                    ) : (
                      <Image src="/images/landing-page/arrow-right.svg" width={32} height={32} />
                    )}
                  </Button>
                </div>
              ) : (
                <Button className={classes.btnSignup} type="submit" color="primary">
                  {isLoading ? (
                    <Spinner color="#fff" variant="small" />
                  ) : (
                    t("LandingPage.SubscribeNewsletter.Subscribe")
                  )}
                </Button>
              )}
            </form>
          </div>
          {(errors.email || errorMessages.length > 0) && (
            <div className={classes.errorContainer}>
              <ErrorMessage
                errorMessage={getEmailErrorMessage()}
                icon={<AlertIcon width={24} height={24} />}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
