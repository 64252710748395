import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useFooterStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      zIndex: "1",
      backgroundColor: "#3f3d56",
      color: "#fff",
    },
    containerInner: {
      maxWidth: "1175px",
      margin: "0 auto",
      flexDirection: "column",
      display: "flex",
      zIndex: "1",
      [theme.breakpoints.down(1100)]: {
        padding: "0 16px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 16px",
      },
      [theme.breakpoints.between("sm", 1250)]: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    columnContainer: {
      display: "flex",
      padding: theme.spacing("50px", 0),
      justifyContent: "space-between",
      [theme.breakpoints.down(871)]: {
        flexDirection: "column",
        padding: theme.spacing("24px", 0),
        gap: "24px",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        padding: theme.spacing("24px", 0),
        gap: "24px",
      },
    },
    copyright: {
      textAlign: "center",
      backgroundColor: "#353348",
      padding: theme.spacing("24px", 0),
      fontSize: "0.9375rem",
      lineHeight: "1.6",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.6875rem",
        lineHeight: "1.45",
      },
    },
    column1: {
      maxWidth: "275px",
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      [theme.breakpoints.down("sm")]: {
        gap: "16px",
        maxWidth: "unset",
      },
    },
    mission: {
      fontSize: "0.9375rem",
    },
    logo: {
      width: "124px",
      height: "48px",
      marginLeft: "-6px",
    },
    column2: {
      maxWidth: "190px",
      "& ul": {
        display: "flex",
        flexDirection: "column",
        gap: "19px",
      },
      "& ul li": {
        textTransform: "uppercase",
        fontSize: "0.9375rem",
        fontWeight: "bold",
        lineHeight: "1.07",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "unset",
      },
    },
    column3: {
      maxWidth: "375px",
      width: "100%",
      "& ul": {
        display: "flex",
        gap: "24px",
      },
      "& ul li": {},
      [theme.breakpoints.down("sm")]: {
        maxWidth: "unset",
      },
    },
    columnSpacing: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    columnSubtitle: {
      fontWeight: "800",
      fontSize: "1.0625rem",
      lineHeight: "1.41",
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        fontWeight: "bold",
        fontSize: "0.9375rem",
      },
    },
    socialIcons: {
      marginBottom: "29px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "24px",
      },
    },
    newsletterForm: {
      display: "flex",
      alignItems: "center",
      gap: "16px",

      "& input": {
        width: "100%",
        padding: "16px",
        color: "#3f3d56",
        fontSize: "0.9375rem",
        borderRadius: "34px",
        border: "none",
        fontWeight: "600",
        fontFamily: theme.typography.fontFamily,

        "&::placeholder": {
          color: "#a9a9b2",
        },
      },
    },
    btnSignup: {
      padding: 0,
    },
    btnTop: {
      width: "64px",
      height: "64px",
      position: "absolute",
      bottom: "-39px",
      right: "-100px",
      transform: "rotate(-90deg)",
      [theme.breakpoints.up(1400)]: {
        right: "-6vw",
      },
      [theme.breakpoints.down(1400)]: {
        right: "2vw",
        width: "56px",
        height: "56px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    inputDiv: {
      width: "100%",
    },
    submitButton: {
      backgroundColor: "#f98012",
      width: 58,
      height: 48,
      borderRadius: "50%",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },
    languageSwitcher: {
      "& span": {
        cursor: "pointer",
      },
    },
    btnTopContainer: {
      height: 1,
      position: "relative",
    },
  };
});
