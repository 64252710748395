import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";

import { Theme, useMediaQuery } from "@mui/material";

import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import { Button } from "@/common/components/button/Button";

import { MOBILE_PLAYSTORE_URL } from "@/common/constants";
import { appstoreLinkEvent, localizedAppstoreURL } from "@/common/services";

import { useHeroStyles } from "./Hero.styles";
type TProps = {
  showAnouncement: boolean;
  isNZ: boolean;
};
export const Hero = ({ showAnouncement, isNZ }: TProps) => {
  const { classes, cx } = useHeroStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const router = useRouter();
  const [videoUrl, setVideoUrl] = useState<string>(router.locale || i18n.language);

  useEffect(() => {
    if (videoRef) {
      setVideoUrl(i18n.language);
      videoRef.current?.load();
    }
  }, [i18n.language, videoRef.current]);

  return (
    <div className={classes.container}>
      <div
        className={cx(classes.containerInner, {
          [classes.innerWithAnouncement]: showAnouncement,
        })}>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, x: ["-50px", "0px"] }}
          transition={{ duration: 0.6 }}
          className={classes.left}>
          <div className={classes.heroTitle}>
            {t("LandingPage.Hero.Title")
              .split("\\n")
              .map((s) => {
                return <p key={s}>{s}</p>;
              })}
          </div>
          <div className={classes.heroSubtitle}>
            {!isNZ && t("LandingPage.Hero.Subtitle")}
            <div className={cx(classes.heroSubtitleDesc, { [classes.noPadding]: isNZ })}>
              {isNZ
                ? t("LandingPage.Hero.SubtitleSmallNZ")
                    .split("\\n")
                    .map((s) => {
                      return <p key={s}>{s}</p>;
                    })
                : t("LandingPage.Hero.SubtitleSmall")
                    .split("\\n")
                    .map((s) => {
                      return <p key={s}>{s}</p>;
                    })}
              {}
            </div>
          </div>
          <div className={classes.heroAppTeaser}>{t("LandingPage.Hero.AppAvailableSoon")}</div>
          <div className={classes.appstore}>
            <Link href={localizedAppstoreURL(i18n.language)}>
              <a target="_blank" className="plausible-event-name=iosButtonClick">
                <Image
                  onClick={() => appstoreLinkEvent("ios")}
                  src={`/images/appstore/${videoUrl}/apple_store.svg`}
                  className={classes.link}
                  width={isMobile ? 183 : 189}
                  height={isMobile ? 62 : 64}></Image>
              </a>
            </Link>
            <Link href={MOBILE_PLAYSTORE_URL}>
              <a target="_blank" className="plausible-event-name=androidButtonClick">
                <Image
                  onClick={() => appstoreLinkEvent("android")}
                  src={`/images/appstore/${videoUrl}/google_store.svg`}
                  className={classes.link}
                  width={189}
                  height={64}></Image>
              </a>
            </Link>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, x: ["50px", "0px"] }}
          transition={{ duration: 0.6 }}
          className={classes.right}>
          {isNZ ? (
            <video
              ref={videoRef}
              autoPlay
              loop
              playsInline
              disablePictureInPicture
              disableRemotePlayback
              controls={false}
              muted={true}>
              <source
                src={`/images/landing-page/nz/animation-nz-final.webm`}
                type="video/webm"></source>
              <source
                src={`/images/landing-page/nz/animation-nz-final.mp4`}
                type="video/mp4"></source>
            </video>
          ) : (
            <video
              ref={videoRef}
              autoPlay
              loop
              playsInline
              disablePictureInPicture
              disableRemotePlayback
              controls={false}
              muted={true}>
              <source
                src={`/images/landing-page/animation-${videoUrl || "en"}-final.webm`}
                type="video/webm"></source>
              <source
                src={`/images/landing-page/animation-${videoUrl || "en"}-final.mp4`}
                type="video/mp4"></source>
            </video>
          )}
        </motion.div>
        <div className={classes.buttonsMobile}>
          <div>
            <Button className={classes.btnSignup} href="/login" color="primary">
              {t("LandingPage.HotTrends.LoginBtn")}
            </Button>
          </div>
          <div>
            <Button className={classes.btnLogin} href="/sign-up" color="primary">
              {t("LandingPage.HotTrends.GetStartedBtn")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
