const AccordeonArrow = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        d="M7.968 11.292a2.192 2.192 0 0 1 2.91-.139l.154.139L16 16.183l4.968-4.891a2.192 2.192 0 0 1 2.91-.139l.154.139a2.11 2.11 0 0 1 .14 2.864l-.14.152-6.5 6.4a2.192 2.192 0 0 1-2.91.139l-.154-.139-6.5-6.4a2.11 2.11 0 0 1 0-3.016z"
        fill="#A9A9B2"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default AccordeonArrow;
