import React from "react";

import { Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import ActivityCard from "@/common/components/ActivityCard";
import Hide from "@/common/components/Hide";
import SkeletonContentCard from "@/common/components/SkeletonContentCard";

import { ActivityCardSlimFragment } from "@/common/models/ActivityModel";

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    wrapper: {
      width: "694px",
      minHeight: "516px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "unset",
      },
    },
    box: {
      display: "flex",
      flexWrap: "wrap",
      "> *": {
        flex: "1 1 160px",
        margin: "10px",
      },
    },
    pullLeft: {
      marginLeft: "-55px",
    },
    pullRight: {
      marginRight: "-55px",
    },
    pullSlightlyLeft: {
      marginLeft: "-2px",
    },
    pull3Right: {
      marginRight: "3px",
    },
    pullSlightlyRight: {
      marginLeft: "2px",
    },
    activityContainer: {
      display: "flex",
      gap: "16px",
    },
    activityScrollContainer: {
      width: "100vw",
      overflow: "auto",
      padding: "32px 0",
      paddingLeft: "16px",
    },
    space: {
      width: 25,
      height: 10,
    },
  };
});

interface HotTrendsProps {
  hotTrends: Array<ActivityCardSlimFragment>;
  loading: boolean;
}

export const HotTrends = ({ hotTrends, loading }: HotTrendsProps) => {
  const { classes } = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  function ActivityCardOrLoader(props: any) {
    return loading ? (
      <SkeletonContentCard size="small" />
    ) : (
      <Hide if={hotTrends.length === 0}>
        <ActivityCard
          hotTrend
          activity={hotTrends[props.index]}
          size="small"
          hideBelonging
          hideHeaderIcon
          cardType={isSmallScreen ? "vertical" : "normal"}
        />
      </Hide>
    );
  }

  return (
    <>
      <div className={classes.wrapper}>
        {!isSmallScreen ? (
          <div className={classes.box}>
            <div className={classes.pullLeft}>
              <ActivityCardOrLoader index={0} />
            </div>
            <div className={classes.pullSlightlyRight}>
              <ActivityCardOrLoader index={1} />
            </div>
            <div className={classes.pull3Right}>
              <ActivityCardOrLoader index={2} />
            </div>
            <div className={classes.pullRight}>
              <ActivityCardOrLoader index={3} />
            </div>
            <div className={classes.pullLeft}>
              <ActivityCardOrLoader index={4} />
            </div>
            <div className={classes.pullSlightlyRight}>
              <ActivityCardOrLoader index={5} />
            </div>
          </div>
        ) : (
          <div className={classes.activityScrollContainer}>
            <div className={classes.activityContainer}>
              <ActivityCardOrLoader index={0} />
              <ActivityCardOrLoader index={1} />
              <ActivityCardOrLoader index={2} />
              <ActivityCardOrLoader index={3} />
              <ActivityCardOrLoader index={4} />
              <ActivityCardOrLoader index={5} />
              <div className={classes.space}>&nbsp;</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
