import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useLandingPageStyles = makeStyles()((theme: Theme) => {
  return {
    srOnly: {
      position: "absolute",
      width: 1,
      height: 1,
      padding: 0,
      margin: -1,
      overflow: "hidden",
      clip: "rect(0, 0, 0, 0)",
      whiteSpace: "nowrap",
      borderWidth: 0,
    },
    landingContainer: {
      display: "flex",
      width: "100%",
      height: "100vh",
      flexDirection: "column",
      fontFamily: theme.typography.fontFamily,
    },
  };
});
