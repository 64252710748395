import React from "react";

import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles()(() => ({
  root: {
    color: "#ff3333",
    marginTop: "3px",
    padding: "0px 9px",
  },
  rootWithIcon: {
    display: "flex",
    alignItems: "center",
  },
  typography: {
    fontSize: "13px",
    fontWeight: 600,
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  typographyWithIcon: {
    marginLeft: "8px",
  },
}));

interface ErrorMessageProps {
  errorMessage?: string;
  type?: "required" | "pattern" | "min" | "max" | "maxLength";
  className?: string;
  icon?: React.ReactNode;
  name?: string;
}

function ErrorMessage(props: ErrorMessageProps) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  let errorMessage = props.errorMessage;
  if (!errorMessage && props.type) {
    errorMessage = t("Generic.ErrorMessages." + props.type);
  }

  return (
    <div
      className={cx(classes.root, !!props.icon && classes.rootWithIcon, props.className)}
      data-cy={props.name ? `${props.name}-error` : undefined}>
      {props.icon}
      <Typography
        className={cx(classes.typography, !!props.icon && classes.typographyWithIcon)}
        classes={{ root: classes.root }}
        variant="subtitle1">
        {errorMessage}
      </Typography>
    </div>
  );
}

export default ErrorMessage;
