// https://emailregex.com/
export const EMAIL_PATTERN =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const USERNAME = {
  PATTERN: /^[A-Za-z][A-Za-z0-9_]*$/,
  MIN_LENGTH: 3,
  MAX_LENGTH: 25,
};

export const PASSWORD = {
  PATTERN: /^(?=.*\d)(?=.*[a-zA-Z])(.+)$/,
  MIN_LENGTH: 8,
  MAX_LENGTH: 30,
};
