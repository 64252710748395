import dynamic from "next/dynamic";
import Image from "next/image";
import React from "react";

import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { Button as Btn } from "@/common/components/button/Button";
import Link from "@/common/components/Link";
import Logo from "@/common/components/LogoLanding";

import { HOMEPAGE_BLOG_URL } from "@/common/constants";

import { Anouncement } from "../anouncement/Anouncement";
import { LanguageSwitcher } from "../language-switcher/LanguageSwitcher";
import { useHeaderStyles } from "./Header.styles";
import { MobileNavDialogProps } from "./MobileNavDialog";

const MobileNavDialog = dynamic<MobileNavDialogProps>(
  () => import("./MobileNavDialog").then((module) => module.MobileNavDialog),
  {
    ssr: false,
  },
);

type TProps = {
  showAnouncement: boolean;
  anouncementOnClose: () => void;
  isNZ: boolean;
};
export const Header = ({ showAnouncement = true, isNZ, anouncementOnClose }: TProps) => {
  const { classes, cx } = useHeaderStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const navObject = [
    {
      link: "/groups",
      label: t("Main.Groups"),
    },
    {
      link: "/debates",
      label: t("Main.Debates"),
    },
    {
      link: "/events",
      label: t("Main.Events"),
    },
    {
      link: "/blogs",
      label: t("Main.Blogs"),
    },
    {
      link: "/petitions",
      label: t("Main.Petitions"),
    },
    {
      link: "/livestreams",
      label: "Livestreams",
    },
    {
      link: HOMEPAGE_BLOG_URL,
      label: t("LandingPage.Header.BlogLink"),
      external: true,
    },
  ];

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={cx(classes.container, {
        [classes.hasAnouncement]: showAnouncement,
      })}>
      {showAnouncement && <Anouncement onClose={() => anouncementOnClose()} />}
      {open && (
        <MobileNavDialog
          onClose={handleClose}
          navObject={!isNZ ? navObject : navObject.filter((entry) => !entry.external)}
          open={open}
        />
      )}
      <div
        className={cx(classes.containerInner, {
          [classes.innerWithAnouncement]: showAnouncement,
        })}>
        <div className={classes.left}>
          <Logo
            width={isMobile ? 104 : undefined}
            height={isMobile ? 40 : undefined}
            className={classes.logo}
            title={isNZ ? "is nz" : "not nz"}
            fill={"#f98012"}></Logo>
        </div>
        <div
          className={cx(classes.center, {
            [classes.offsetNZ]: !isNZ,
          })}>
          <div className={classes.nav}>
            <ul>
              {navObject
                .filter((entry) => !entry.external || (entry.external && !isNZ))
                .map((link, i) => {
                  return (
                    <li key={`nav_${i}`}>
                      <Link prefetch={false} href={`${link.link}`} externalLink={link.external}>
                        <a>{link.label}</a>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className={classes.right}>
          {!isNZ && (
            <div className={classes.languageSwitcher}>
              <LanguageSwitcher />
            </div>
          )}
          <div className={classes.buttons}>
            <div>
              <Btn className={classes.btnSignup} href="/login" color="primary">
                {t("LandingPage.HotTrends.LoginBtn")}
              </Btn>
            </div>
            <div>
              <Btn className={classes.btnLogin} href="/sign-up" color="primary">
                {t("LandingPage.HotTrends.GetStartedBtn")}
              </Btn>
            </div>
          </div>
          <div className={classes.mobileMenuIcon}>
            <Image
              onClick={handleClickOpen}
              src="/images/landing-page/hamburger.svg"
              height="24"
              width="24"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
