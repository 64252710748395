import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useAnouncementStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      backgroundColor: "#3f3d56",
    },
    containerInner: {
      height: "56px",
      lineHeight: "56px",
      fontSize: "1.0625rem",
      textAlign: "center",
      color: "white",
      fontWeight: "bold",
      [theme.breakpoints.down(1100)]: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        lineHeight: "40px",
        height: "40px",
        paddingLeft: "16px",
        paddingRight: "16px",
        textAlign: "left",
        fontSize: "0.8125rem",
      },
    },
    closeIcon: {
      position: "absolute",
      top: "15px",
      right: "24px",
      color: "white",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        top: "9px",
        right: "16px",
      },
    },
    learnMore: {
      color: "#f98012",
      fontSize: "0.9375rem",
      textTransform: "uppercase",
      fontWeight: "800",
      lineHeight: "1.6",
    },
  };
});
