import React from "react";

import { useTranslation } from "react-i18next";

import { Button } from "@/common/components/button/Button";
import { HotTrends as Trends } from "@/home/components/HotTrends";

import { useHomeStore } from "@/home/hooks";

import { GeographicalInterestsList } from "@/graphql/types";

import { useHotTrendsStyles } from "./HotTrends.styles";

type TProps = {
  isNZ: boolean;
  ipLocation: GeographicalInterestsList | undefined;
};
export const HotTrends = ({ isNZ, ipLocation }: TProps) => {
  const { classes } = useHotTrendsStyles();
  const { t } = useTranslation();
  const { hotTrends: trendsList, loading } = useHomeStore({
    limit: 6,
    geographicalInterest: isNZ ? GeographicalInterestsList.Nz : ipLocation,
  });

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        <div className={classes.left}>
          <div className={classes.title}>{t("LandingPage.HotTrends.Title")}</div>
          <div className={classes.subtitle}>{t("LandingPage.HotTrends.Subtitle")}</div>
          <div className={classes.buttonsDesktop}>
            <div>
              <Button className={classes.btnSignup} href="/login" color="primary">
                {t("LandingPage.HotTrends.LoginBtn")}
              </Button>
            </div>
            <div>
              <Button className={classes.btnLogin} href="/sign-up" color="primary">
                {t("LandingPage.HotTrends.GetStartedBtn")}
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.right}>
          <Trends hotTrends={trendsList} loading={loading} />
        </div>
        <div className={classes.buttonsMobile}>
          <div>
            <Button className={classes.btnSignup} href="/login" color="primary">
              {t("LandingPage.HotTrends.LoginBtn")}
            </Button>
          </div>
          <div>
            <Button className={classes.btnLogin} href="/sign-up" color="primary">
              {t("LandingPage.HotTrends.GetStartedBtn")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
