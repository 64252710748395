import dynamic from "next/dynamic";
import Image from "next/image";
import React, { useState } from "react";

import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { Button } from "@/common/components/button/Button";
import Logo from "@/common/components/LogoLanding";

import { useVideoStyles } from "./Video.styles";
import type { VideoDialogProps } from "./VideoDialog";

const VideoDialog = dynamic<VideoDialogProps>(
  () => import("./VideoDialog").then((module) => module.VideoDialog),
  {
    ssr: false,
  },
);
export const Video = () => {
  const { t } = useTranslation();
  const { classes } = useVideoStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);

  const openVideoDialog = () => {
    setIsVideoDialogOpen(true);
  };

  const closeVideoDialog = () => {
    setIsVideoDialogOpen(false);
  };

  const logoDimensions = {
    h: isMobile ? 72 : 120,
    w: isMobile ? 166 : 276,
  };
  const playDimensions = {
    h: isMobile ? 56 : 80,
    w: isMobile ? 56 : 80,
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        <div className={classes.left}>
          <div className={classes.title}>{t("LandingPage.Video.SectionTitle")}</div>
          <div className={classes.subtitle}>
            {t("LandingPage.Video.SectionDescription1")}
            <br />
            <br />
            {t("LandingPage.Video.SectionDescription2")}
            <br />
            <br />
            {t("LandingPage.Video.SectionDescription3")}
          </div>
          <div className={classes.videoLink} onClick={openVideoDialog}>
            {t("LandingPage.Video.SeeVideoLinkText")}
          </div>
          <div className={classes.buttonsDesktop}>
            <div>
              <Button className={classes.btnSignup} href="/login" color="primary">
                {t("LandingPage.HotTrends.LoginBtn")}
              </Button>
            </div>
            <div>
              <Button className={classes.btnLogin} href="/sign-up" color="primary">
                {t("LandingPage.HotTrends.GetStartedBtn")}
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.orangeBg}></div>
          <div className={classes.videoSquare} onClick={openVideoDialog}>
            <div className={classes.videoSquareLogo}>
              <Logo width={logoDimensions.w} height={logoDimensions.h} fill={"#fff"}></Logo>
            </div>
            <div className={classes.videoSquarePlay}>
              <Image
                src="/images/landing-page/play-button.svg"
                width={playDimensions.w}
                height={playDimensions.h}
              />
            </div>
          </div>
        </div>
        <div className={classes.buttonsMobile}>
          <div>
            <Button className={classes.btnSignup} href="/login" color="primary">
              {t("LandingPage.HotTrends.LoginBtn")}
            </Button>
          </div>
          <div>
            <Button className={classes.btnLogin} href="/sign-up" color="primary">
              {t("LandingPage.HotTrends.GetStartedBtn")}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.bgSquare}></div>
      {isVideoDialogOpen && <VideoDialog onClose={closeVideoDialog} />}
    </div>
  );
};
