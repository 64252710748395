import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useNewsletterStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      zIndex: "1",
      backgroundColor: "#fff",
      display: "flex",
      alignItems: "center",
      paddingTop: "112px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "0px",
      },
    },
    containerInner: {
      maxWidth: "1175px",
      width: "100%",
      margin: "0 auto",
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing("80px", 0),
      [theme.breakpoints.down(1100)]: {
        minHeight: "unset",
        margin: "0 16px",
        padding: theme.spacing("104px", 0),
      },
      [theme.breakpoints.between("sm", 1250)]: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    bgSquare: {
      backgroundColor: "#f6f6f6",
      width: "32.5%",
      height: "592px",
      borderRadius: "0px 32px 32px 0px",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        height: "397px",
        width: "39.6%",
      },
    },
    fgSquare: {
      padding: "88px 100px",
      backgroundColor: "#3f3d56",
      borderRadius: "48px",
      maxWidth: "1175px",
      height: "100%",
      width: "100%",
      zIndex: 6,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down(1100)]: {
        padding: "65px 30px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "32px 16px",
        borderRadius: "16px",
      },
    },
    sectionTitle: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      lineHeight: "1.33",
      color: "#fff",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
        textAlign: "left",
        fontWeight: "800",
        lineHeight: "1.13",
        marginTop: "-5px",
      },
    },
    sectionSubTitle: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      color: "#fff",
      lineHeight: "1.41",
      fontSize: "1.0625rem",
      textAlign: "center",
      maxWidth: "700px",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        paddingBottom: "24px",
        lineHeight: "unset",
      },
    },
    btnSignup: {
      width: "275px",
      height: "72px",
      fontWeight: "bold",
      display: "inline-flex",
      fontSize: "1.3125rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      justifyContent: "center",
      minHeight: "unset",
      lineHeight: "1.14",
      [theme.breakpoints.down("sm")]: {
        height: "48px",
      },
    },
    btnSignupMobile: {
      padding: "0",
    },
    formContainer: {
      display: "flex",
      width: "100%",
    },
    errorContainer: {
      display: "flex",
      width: "100%",
      marginTop: "10px",
    },
    hidden: {
      display: "none",
    },
    form: {
      display: "flex",
      gap: "24px",
      width: "100%",
      height: "72px",
      [theme.breakpoints.down("sm")]: {
        gap: "8px",
        height: "48px",
      },

      "& input": {
        borderRadius: "32px",
        width: "100%",
        border: "none",
        height: "72px",
        fontSize: "1.3125rem",
        fontWeight: "600",
        lineHeight: "1.14",
        padding: "0px 30px",
        color: "#3f3d56",
        fontFamily: theme.typography.fontFamily,
        [theme.breakpoints.down("sm")]: {
          height: "48px",
          borderRadius: "34px",
          fontSize: "0.9375rem",
          padding: "0px 16px",
        },

        "&::placeholder": {
          color: "#a9a9b2",
        },
      },
    },
    submitButton: {
      backgroundColor: "#f98012",
      width: 58,
      height: 48,
      borderRadius: "50%",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});
