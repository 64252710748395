import Image from "next/image";
import Link from "next/link";
import React from "react";

import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { MOBILE_PLAYSTORE_URL } from "@/common/constants";
import { appstoreLinkEvent, localizedAppstoreURL } from "@/common/services";

import { useStoreLinksStyles } from "./StoreLinks.styles";
export const StoreLinks = () => {
  const { classes } = useStoreLinksStyles();
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <div className={classes.container} id="apps">
      <div className={classes.bgSquare}></div>
      <div className={classes.containerInner}>
        <div className={classes.left}>
          <div className={classes.title}>
            {t("LandingPage.AppStore.SectionTitle")
              .split("\\n")
              .map((s) => {
                return <p key={s}>{s}</p>;
              })}
          </div>
        </div>
        <div className={classes.right}>
          <Link href={localizedAppstoreURL(i18n.language)}>
            <a target="_blank" className="plausible-event-name=iosButtonClick">
              <Image
                onClick={() => appstoreLinkEvent("ios")}
                src={`/images/appstore/${i18n.language}/apple_store.svg`}
                className={classes.link}
                width={isMobile ? 183 : 235}
                height={isMobile ? 62 : 80}></Image>
            </a>
          </Link>
          <Link href={MOBILE_PLAYSTORE_URL}>
            <a target="_blank" className="plausible-event-name=androidButtonClick">
              <Image
                onClick={() => appstoreLinkEvent("android")}
                src={`/images/appstore/${i18n.language}/google_store.svg`}
                className={classes.link}
                width={isMobile ? 183 : 235}
                height={isMobile ? 62 : 80}></Image>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};
