import React from "react";

import { useTranslation } from "react-i18next";

import Link from "@/common/components/Link";

import { HOMEPAGE_BLOG_URL } from "@/common/constants";

import { useBlogStyles } from "./Blog.styles";
export const Blog = () => {
  const { classes } = useBlogStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        <div className={classes.sectionTitle}>{t("LandingPage.ExternalBlog.SectionTitle")}</div>
        <div className={classes.sectionSubTitle}>
          {t("LandingPage.ExternalBlog.SectionSubtitle")}
        </div>
        <div className={classes.row1}>
          <div className={classes.link}>
            <Link href={HOMEPAGE_BLOG_URL} externalLink>
              <a>{t("LandingPage.ExternalBlog.ExternalLinkText")}</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
