import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useToolkitStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      display: "flex",
      zIndex: "1",
      backgroundColor: "#f7f7f7",
    },
    containerInner: {
      maxWidth: "1175px",
      margin: "0 auto",
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing("80px", 0),
      [theme.breakpoints.down(1100)]: {
        padding: theme.spacing("46px", "16px"),
      },
      [theme.breakpoints.between("sm", 1250)]: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    sectionTitle: {
      fontSize: "2.5rem",
      fontWeight: "800",
      lineHeight: "1.2",
      color: "#3f3d56",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
        textAlign: "left",
        lineHeight: "1.25",
      },
    },
    sectionSubTitle: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(9),
      color: "#3f3d56",
      lineHeight: "1.41",
      fontSize: "1.0625rem",
      textAlign: "center",
      maxWidth: "975px",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        paddingTop: theme.spacing(1),
        paddingBottom: "43px",
        lineHeight: "unset",
      },
    },
    row1: {
      display: "flex",
      gap: "78px",
      paddingBottom: "67px",
      [theme.breakpoints.down(1100)]: {
        gap: "48px",
        paddingBottom: theme.spacing(6),
      },
      [theme.breakpoints.down(938)]: {
        gap: "18px",
      },
      [theme.breakpoints.down(756)]: {
        flexDirection: "column",
        gap: "48px",
      },
    },
    row2: {
      display: "flex",
      gap: "78px",
      [theme.breakpoints.down(1100)]: {
        gap: "48px",
        paddingBottom: theme.spacing(6),
      },
      [theme.breakpoints.down(938)]: {
        gap: "18px",
      },
      [theme.breakpoints.down(756)]: {
        flexDirection: "column",
        gap: "48px",
        paddingBottom: "12px",
      },
    },
    rowCell: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "340px",
    },
    header: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
    },
    headerIconWrapper: {},
    headerIconRound: {
      backgroundColor: "#f98012",
      width: 56,
      height: 56,
      borderRadius: "50%",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },
    headerTitle: {
      fontSize: "1.5rem",
      color: "#3f3d56",
      lineHeight: "1.33",
      fontWeight: "800",
    },
    description: {
      padding: "16px 0px",
      fontSize: "1.0625rem",
      lineHeight: "1.41",
      color: "#6c6b78",
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.0625rem",
        lineHeight: "unset",
      },
    },
    link: {
      fontSize: "1.0625rem",
      color: "#f98012",
      lineHeight: "1.41",
      fontWeight: 800,
      textTransform: "uppercase",
    },
    accordionRoot: {
      // backgroundColor: "red",
      backgroundColor: "unset",
      margin: "0px",
      "&::before": {
        height: "0px",
      },
      "&.Mui-expanded": {
        margin: "0px",
      },
    },
    accordionSummaryRoot: {
      padding: "0px",
      margin: "0px",
      "& .MuiAccordionSummary-content": {
        margin: "0px",
      },
      "&.Mui-expanded": {
        margin: "0px !important",
        minHeight: "unset",
      },
    },
    accordionDetailsRoot: {
      padding: "0px",
    },
  };
});
