import React from "react";

import { useTranslation } from "react-i18next";

import CloseIcon from "@/common/icons/CloseIcon";

import { useAnouncementStyles } from "./Anouncement.styles";

type TProps = {
  onClose: () => void;
};
export const Anouncement = ({ onClose }: TProps) => {
  const { classes } = useAnouncementStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        <span>
          <a
            href="#"
            onClick={(e) => {
              const appSection = document.getElementById("apps");
              e.preventDefault(); // Stop Page Reloading
              appSection && appSection.scrollIntoView({ behavior: "smooth", block: "center" });
            }}>
            {t("LandingPage.Announcement.Message")}
          </a>
        </span>
      </div>
      <CloseIcon onClick={onClose} className={classes.closeIcon} />
    </div>
  );
};
