import { useState } from "react";

import SEO from "@/common/components/SEO";

import { useLocalStorage } from "@/common/hooks";

import { getGeoInterestEnumFromString } from "@/common/services";
import { useFlag } from "@/feature-flags/contexts/FlagsContext";
import { useLandingPageStyles } from "@/landing-page/LandingPage.styles";

import { Blog } from "./components/blog/Blog";
import { Footer } from "./components/footer/Footer";
import { Header } from "./components/header/Header";
import { Hero } from "./components/hero/Hero";
import { HotTrends } from "./components/hotTrends/HotTrends";
import { Newsletter } from "./components/newsletter/Newsletter";
import { StoreLinks } from "./components/storeLinks/StoreLinks";
import { Toolkit } from "./components/toolkit/Toolkit";
import { Video } from "./components/video/Video";

type TProps = {
  ipLocation?: string;
};
const LandingPage = ({ ipLocation }: TProps) => {
  const { classes } = useLandingPageStyles();
  const [showAnouncement, setShowAnouncement] = useState<boolean>(true);
  const { enabled } = useFlag("FE_LANDING_ANNOUNCEMENT");
  const locationGeoInterest = getGeoInterestEnumFromString(ipLocation);
  const [anouncementDismissed, setAnnouncementDismissed] = useLocalStorage(
    "anouncementDismissed",
    "false",
  );

  const anouncementNotDismissedLS = anouncementDismissed !== "true";

  const handleAnnouncementClose = () => {
    setAnnouncementDismissed("true");
    setShowAnouncement(false);
  };

  return (
    <>
      <SEO />
      <h1 aria-hidden className={classes.srOnly}>
        PoliticAll
      </h1>
      <h1 aria-hidden className={classes.srOnly}>
        IP location: {ipLocation}
      </h1>
      <div className={classes.landingContainer}>
        <Header
          showAnouncement={enabled && showAnouncement && anouncementNotDismissedLS}
          isNZ={ipLocation === "NZ" || ipLocation === "NL"}
          anouncementOnClose={() => handleAnnouncementClose()}
        />
        <Hero
          isNZ={ipLocation === "NZ" || ipLocation === "NL"}
          showAnouncement={enabled && showAnouncement && anouncementNotDismissedLS}
        />
        <Toolkit />
        <HotTrends
          ipLocation={locationGeoInterest}
          isNZ={ipLocation === "NZ" || ipLocation === "NL"}
        />
        {ipLocation !== "NZ" && ipLocation !== "NL" && <Blog />}
        <Newsletter />
        <Video />
        <StoreLinks />
        <Footer isNZ={ipLocation === "NZ" || ipLocation === "NL"} />
      </div>
    </>
  );
};

export default LandingPage;
