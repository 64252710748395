import { Theme, darken } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useVideoStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      display: "flex",
      backgroundColor: "white",
    },
    containerInner: {
      width: "1175px",
      margin: "0 auto",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      padding: "150px 0px 160px 0px",
      [theme.breakpoints.down(1100)]: {
        flexDirection: "column",
        padding: "75px 16px 80px 16px",
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        padding: "0px 16px 0px 16px",
        width: "100%",
      },
      [theme.breakpoints.between("sm", 1250)]: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    bgSquare: {
      backgroundColor: "#f98012",
      width: "32.5%",
      height: "674px",
      marginTop: "160px",
      borderRadius: "32px 0px 0px 32px",
      position: "absolute",
      right: 0,
      [theme.breakpoints.down(1100)]: {
        display: "none",
      },
    },
    orangeBg: {
      height: "354px",
      width: "32.5%",
      borderRadius: "32px 0px 0px 32px",
      position: "absolute",
      zIndex: 2,
      right: 0,
      backgroundColor: "#f98012",
      margintop: "48px",

      [theme.breakpoints.up(1100)]: {
        display: "none",
      },
    },
    left: {
      maxWidth: "475px",
      paddingTop: "74px",
      [theme.breakpoints.down(1100)]: {
        maxWidth: "unset",
        paddingTop: "0px",
      },
    },
    right: {
      display: "flex",
      [theme.breakpoints.down(1100)]: {
        height: "450px",
        alignItems: "center",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    title: {
      fontSize: "2.5rem",
      fontWeight: "800",
      lineHeight: "1.26",
      color: "#3f3d56",
      [theme.breakpoints.down(1100)]: {
        fontSize: "2rem",
      },
    },
    subtitle: {
      fontSize: "1.0625rem",
      lineHeight: "1.41",
      color: "#3f3d56",
      paddingTop: "12px",
      [theme.breakpoints.down(1100)]: {
        lineHeight: "unset",
      },
    },
    videoLink: {
      fontSize: "1.0625rem",
      fontWeight: "800",
      lineHeight: "1.14",
      color: "#f98012",
      textTransform: "uppercase",
      padding: "24px 0px",
      cursor: "pointer",
      [theme.breakpoints.down(1100)]: {
        lineHeight: "1.41",
        padding: "24px 0px 0px 0px",
      },
    },
    buttonsDesktop: {
      display: "flex",
      gap: theme.spacing(3),
      [theme.breakpoints.down(1100)]: {
        display: "none",
      },
    },
    buttonsMobile: {
      display: "none",
      paddingTop: "19px",
      gap: theme.spacing(3),
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.down(1100)]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingTop: "0px",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "unset",
        paddingTop: "0px",
      },
    },
    btnLogin: {
      width: "168px",
      height: "48px",
      fontWeight: "800",
      display: "inline-flex",
      fontSize: "1.0625rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      justifyContent: "center",
      minHeight: "unset",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "56px",
        marginBottom: "56px",
      },
    },
    btnSignup: {
      width: "168px",
      height: "48px",
      fontWeight: "800",
      display: "inline-flex",
      fontSize: "1.0625rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      justifyContent: "center",
      minHeight: "unset",
      backgroundColor: "white",
      border: "3px solid #f98012",
      color: "#f98012",
      "&:hover": {
        backgroundColor: "white !important",
        color: darken("#f98012", 0.05),
        borderColor: darken("#f98012", 0.05),
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "56px",
      },
    },
    videoSquare: {
      width: "639px",
      height: "404px",
      borderRadius: "32px",
      backgroundColor: "#3f3d56",
      zIndex: "2",
      marginTop: "67px",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
      [theme.breakpoints.down(1192)]: {
        width: "608px",
        height: "387px",
        marginRight: "16px",
      },
      [theme.breakpoints.down(1109)]: {
        width: "525px",
        height: "304px",
        marginRight: "16px",
      },
      [theme.breakpoints.down(1100)]: {
        height: "242px",
        marginTop: "0px",
        marginRight: "0",
      },
    },
    videoSquareLogo: {
      position: "absolute",
    },
    videoSquarePlay: {
      position: "absolute",
      zIndex: "5",
    },
  };
});
