import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "@/common/components/button/Button";
import ErrorMessage from "@/common/components/ErrorMessage";
import Link from "@/common/components/Link";
import Logo from "@/common/components/LogoLanding";
import Spinner from "@/common/components/Spinner";

import AlertIcon from "@/common/icons/AlertIcon";

import { useNewslettersStore } from "@/newsletters/hooks";

import { EMAIL_PATTERN } from "@/common/constants/validation";

import { useFooterStyles } from "./Footer.styles";

type TProps = {
  isNZ: boolean;
};

export const Footer = ({ isNZ }: TProps) => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { classes } = useFooterStyles();
  const newsletterInputPlaceholder = t("LandingPage.SubscribeNewsletter.Placeholder", {
    lng: router.locale,
  });
  const changeLanguage = async (lng: string) => {
    try {
      await i18n.changeLanguage(lng);
      await router.push(router.pathname, router.pathname, { locale: lng });
    } catch {}
  };

  const { subscribeNewsletter, loading: isLoading, errorMessages } = useNewslettersStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: resetForm,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmitHandler = async (data: any) => {
    const response = await subscribeNewsletter({
      email: data.email,
      language: i18n.language,
    });

    if (response.data?.subscribeNewsletter?.successful) {
      resetForm({ email: "" });
      alert(t("LandingPage.SubscribeNewsletter.DescriptionSent"));
    }
  };

  function getEmailErrorMessage() {
    if (errors.email) {
      return t(`LandingPage.SubscribeNewsletter.EmailAddress.Validation.${errors.email.type}`);
    }

    return (
      t(`LandingPage.SubscribeNewsletter.EmailAddress.Validation.${errorMessages[0]?.message}`) ||
      ""
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        <div className={classes.columnContainer}>
          <div className={classes.column1}>
            <div>
              <Logo className={classes.logo} fill={"#fff"}></Logo>
            </div>
            <div className={classes.mission}>{t("LandingPage.Footer.MissionText")}</div>
          </div>
          <div className={classes.column2}>
            <ul>
              <li>
                <Link href={"/about"}>
                  <a>{t("LandingPage.Footer.About")}</a>
                </Link>
              </li>
              <li>
                <Link href={"/code-of-conduct"}>
                  <a>{t("LandingPage.Footer.CodeOfConduct")}</a>
                </Link>
              </li>
              <li>
                <Link href={"/terms-and-conditions"}>
                  <a>{t("LandingPage.Footer.Terms")}</a>
                </Link>
              </li>
              <li>
                <Link href={"/privacy-policy"}>
                  <a>{t("LandingPage.Footer.Privacy")}</a>
                </Link>
              </li>
              <li className={classes.languageSwitcher}>
                <span onClick={() => changeLanguage("en")}>{t("LandingPage.Footer.English")}</span>{" "}
                | <span onClick={() => changeLanguage("fr")}>{t("LandingPage.Footer.French")}</span>
              </li>
            </ul>
          </div>
          <div className={classes.column3}>
            <div className={classes.columnSpacing}>
              <div className={classes.columnSubtitle}>{t("LandingPage.Footer.SocialFollowUs")}</div>
              <div className={classes.socialIcons}>
                <ul>
                  <li>
                    <Link
                      externalLink
                      href={
                        isNZ
                          ? "https://www.facebook.com/profile.php?id=100095131843701"
                          : "https://www.facebook.com/politicallfr/"
                      }>
                      <a target={"_blank"}>
                        <Image src="/images/social-media-icons/fb.svg" width={24} height={24} />
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      externalLink
                      href={
                        isNZ
                          ? "https://www.instagram.com/politicall.nz/"
                          : "https://www.instagram.com/politicall.fr/"
                      }>
                      <a target={"_blank"}>
                        <Image src="/images/social-media-icons/ig.svg" width={24} height={24} />
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      externalLink
                      href={
                        isNZ
                          ? "https://twitter.com/PoliticAll_NZ"
                          : "https://twitter.com/PoliticallFR/"
                      }>
                      <a target={"_blank"}>
                        <Image
                          src="/images/social-media-icons/twitter.svg"
                          width={24}
                          height={24}
                        />
                      </a>
                    </Link>
                  </li>
                  {!isNZ && (
                    <li>
                      <Link externalLink href={"https://www.linkedin.com/company/politicallfr/"}>
                        <a target={"_blank"}>
                          <Image
                            src="/images/social-media-icons/linkedin.svg"
                            width={24}
                            height={24}
                          />
                        </a>
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      externalLink
                      href={
                        isNZ
                          ? "https://www.tiktok.com/@politicall_nz"
                          : "https://www.tiktok.com/@politicallfr?_t=8XofjaYSanO&_r=1"
                      }>
                      <a target={"_blank"}>
                        <Image src="/images/social-media-icons/tiktok.svg" width={24} height={24} />
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className={classes.columnSpacing}>
              <div className={classes.columnSubtitle}>
                {t("LandingPage.Footer.SubscribeNewsletter")}
              </div>
              <div>
                <form
                  onSubmit={handleSubmit(onSubmitHandler)}
                  className={classes.newsletterForm}
                  noValidate
                  id="newsletter">
                  <div className={classes.inputDiv}>
                    <input
                      {...register("email", {
                        required: true,
                        pattern: EMAIL_PATTERN,
                      })}
                      type={"email"}
                      placeholder={newsletterInputPlaceholder}
                    />
                  </div>
                  <div className={classes.submitButton}>
                    <Button className={classes.btnSignup} type="submit">
                      {isLoading ? (
                        <Spinner color="#fff" variant="small" />
                      ) : (
                        <Image src="/images/landing-page/arrow-right.svg" width={32} height={32} />
                      )}
                    </Button>
                  </div>
                </form>
              </div>
              {(errors.email || errorMessages.length > 0) && (
                <div>
                  <ErrorMessage
                    errorMessage={getEmailErrorMessage()}
                    icon={<AlertIcon width={24} height={24} />}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes.btnTopContainer}>
          <Button
            className={classes.btnTop}
            onClick={() => window && window.scrollTo({ top: 0, behavior: "smooth" })}>
            <Image src="/images/landing-page/arrow-right.svg" width={32} height={32} />
          </Button>
        </div>
      </div>
      <div className={classes.copyright}>{t("LandingPage.Footer.Copyright")}</div>
    </div>
  );
};
