import {
  useSubscribeNewsletterMutation,
  SubscribeNewsletterMutationVariables,
} from "@/graphql/types";

export const useNewslettersStore = () => {
  const [subscribeNewsletter, { loading, data }] = useSubscribeNewsletterMutation();

  return {
    loading,

    get successful() {
      return data?.subscribeNewsletter?.successful;
    },
    get errorMessages() {
      if (data) {
        return (!data.subscribeNewsletter?.successful && data.subscribeNewsletter?.messages) || [];
      }

      return [];
    },

    subscribeNewsletter({ email, language }: SubscribeNewsletterMutationVariables) {
      return subscribeNewsletter({ variables: { email, language } });
    },
  };
};
