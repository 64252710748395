import { ActivityCardSlimFragment } from "@/common/models/ActivityModel";
import {
  ExtendedActivityType,
  GeographicalInterestsList,
  useHotTrendsQuery,
} from "@/graphql/types";

type FetchHotTrendsProps = {
  limit?: number;
  type?: ExtendedActivityType;
  geographicalInterest?: GeographicalInterestsList;
};

export const useHomeStore = ({ type, limit, geographicalInterest }: FetchHotTrendsProps) => {
  const {
    data,
    fetchMore: fetchMoreHotTrends,
    loading,
  } = useHotTrendsQuery({ variables: { type, limit, geographicalInterest } });

  return {
    hotTrends: data?.hotTrends.edges.map(({ node }) => node as ActivityCardSlimFragment) || [],
    loading: loading,
    hasMoreHotTrends: data?.hotTrends.pageInfo.hasNextPage || false,
    fetchNextHotTrendsPage() {
      if (fetchMoreHotTrends && data) {
        return fetchMoreHotTrends({
          variables: {
            after: data.hotTrends.pageInfo.endCursor,
          },
        });
      }
    },
  };
};
