import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStoreLinksStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      zIndex: "1",
      backgroundColor: "#fff",
      paddingTop: "126px",
      paddingBottom: "80px",
      [theme.breakpoints.down(1100)]: {
        paddingBottom: "40px",
        paddingTop: "80px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "56px",
        paddingTop: "unset",
      },
    },
    containerInner: {
      maxWidth: "1175px",
      margin: "0 auto",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      padding: theme.spacing("150px", 0),
      minHeight: "400px",
      zIndex: "5",
      [theme.breakpoints.down(1100)]: {
        padding: "0 16px",
        gap: "16px",
      },
      [theme.breakpoints.down(763)]: {
        flexDirection: "column",
        justifyContent: "center",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 16px",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "302px",
        gap: "28px",
      },
      [theme.breakpoints.between("sm", 1250)]: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    bgSquare: {
      backgroundColor: "#f6f6f6",
      width: "21.32%",
      height: "400px",
      borderRadius: "0px 32px 32px 0px",
      position: "absolute",
      zIndex: "2",
      [theme.breakpoints.down("sm")]: {
        height: "302px",
        width: "39.61%",
      },
    },
    left: {
      zIndex: "3",
    },
    right: {
      display: "flex",
      gap: "40px",
      zIndex: "2",
      [theme.breakpoints.down("sm")]: {
        gap: "16px",
        justifyContent: "space-between",
      },
    },
    title: {
      maxWidth: "475px",
      color: "#3f3d56",
      fontSize: "2.5rem",
      lineHeight: "1.26",
      fontWeight: "800",
      [theme.breakpoints.down(763)]: {
        maxWidth: "unset",
        textAlign: "center",
        fontSize: "2rem",
      },
    },
    link: {
      cursor: "pointer",
    },
  };
});
